
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import image from "../images/cs.jpg";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
      return 0;
    });
    return images;
  }
  
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg|pdf)$/)
  );
  
  const styles = theme => ({
    root: theme.mixins.gutters({
      margin: 3,
      //paddingTop: "1%",
      paddingBottom: "3%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    }),
    paper: {
      //margin: 1,
      paddingTop: "1%",
      paddingBottom: "1%",
      paddingLeft: "1%",
      paddingRight: "1%",
      marginTop: "10px",
      flexGrow: 1
    },
    text: {
      "line-height": "100%"
    },
    title: {
      "border-left": "solid #ff0000cc 5px",
      //'border-top': 'solid red 5px',
      padding: "1%",
      paddingTop: "1%",
      "background-color": "#f7f5f5"
    },
    autoGrid: {
      padding: theme.spacing.unit * 2
    }
  });
class BioCNGToken extends Component {
    constructor(props) {
      super(props);
      this.state = {
      }
    }

    render(){
        const { classes } = this.props;
        return(
            <React.Fragment>
            <Helmet>
              <title>Bio-CNG Token | SFarmsIndia.com</title>
              {/* <meta
                name="description"
                content="SFarmsIndia is the first Agri Land Marketplace in India. It was established for the sole purpose of enabling farmers to sell Agriculture Lands and Eco Farms.
                "
              /> */}
            </Helmet>
            <div className={classes.root}>
            <Typography className={classes.title} variant="title" gutterBottom>
            <b>Bio-CNG Token</b>
              </Typography>
              {/* <Paper className={classes.paper} elevation={6}> */}

                        
              <img
                    src={image}
                    alt="bio-cng"
                    style={{ height: "400px",  width: "400px", marginLeft:"-40px", borderRadius:"5%"}}
                  /> 
                        
                    {/* </Paper> */}
            </div>
          </React.Fragment>
        );
    }
    }

    BioCNGToken.propTypes = {
        classes: PropTypes.object.isRequired
      };
      
      export default withStyles(styles)(BioCNGToken);


