import axios from "./baseAxios";

// import store from "store";

// import APIs from "../constants";

// const hostServerAddress = APIs.BASE_URL;
// const port = APIs.PORT;
export async function getBookedDetails(id) {
  try {
    const response = await axios.get("/pro/book/" + id
      // {
      //   headers: {
      //     "x-access-token": store.get("user")
      //       ? store.get("user").token
      //       : undefined
      //   },
      //   withCredentials: true
      // }
    );
    // console.log('========== get product Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data.statusCode === 201 || response.data.statusCode === 200) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("==============get product Error======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}
async function getProDetails(obj) {
  try {
    const response = await axios.post("/pro/prodetails", obj);
    // console.log('==========Sign Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("==============Pancard Error ======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}
async function getLocationDetails(obj) {
  try {
    const response = await axios.post("/pro/locationview", obj);
    // console.log('==========Sign Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("==============Pancard Error ======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}
export async function getIsPro() {
  try {
    const response = await axios.put("/pro/is", {});
    // console.log('==========Sign Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("==============Pancard Error ======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}

async function upgradePro(obj) {
  try {
    const response = await axios.put("/pro/upgrade/", obj);
    // console.log('==========Sign Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("=============Wallet withdraw Error ======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}

async function proAddCredits(obj) {
  try {
    const response = await axios.put("/pro/addcredits/", obj);
    // console.log('==========Sign Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("=============Wallet withdraw Error ======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}

async function putProDepositDetails(id, obj) {
  try {
    const response = await axios.put("/pro/request/deposit/" + id, obj);
    // console.log('==========Sign Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("==============Pancard Error ======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}

async function getIsProPlus(id) {
  try {
    const response = await axios.get("/pro/proplus/" + id);
    // console.log('========== get product Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data.statusCode === 201 || response.data.statusCode === 200) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("==============get product Error======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}


async function getProDepositDetails(obj) {
  try {
    const response = await axios.get("/pro/deposits");
    // console.log('==========Sign Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("==============Pancard Error ======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}
export async function putSiteVisit(obj) {
  try {
    const response = await axios.put("/pro/visit",
      obj
      // {
      //   headers: {
      //     "x-access-token":
      //       store.get("user") !== null ? store.get("user").token : undefined
      //   },
      //   withCredentials: true
      // }
    );
    // console.log('==========Sign Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data.statusCode === 201) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("==============Sign Error======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}

async function postProWalletdeposit(obj) {
  try {
    const response = await axios.post("/pro/request/deposit/" + obj.id, obj);
    // console.log('==========Sign Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("==============Wallet deposit Error ======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}

var serviceObj = {
  getProDetails,
  proAddCredits,
  getIsPro,
  upgradePro,
  putProDepositDetails,
  postProWalletdeposit,
  getProDepositDetails,
  getIsProPlus,
  getBookedDetails,
  putSiteVisit,
  getLocationDetails
};

export default serviceObj;
