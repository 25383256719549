import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import { Helmet } from "react-helmet";
import ProService from "./api/ProAccountService";
import store from "store";
import green from "@material-ui/core/colors/green";
import Button from "@material-ui/core/Button";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
    return 0;
  });
  return images;
}
const images = importAll(
  require.context("./components/images/", false, /\.(png|jpe?g|svg)$/)
);
// const styles = theme => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//     width: 500,
//     position: "relative",
//     minHeight: 200
//   },
//   fab: {
//     display: "flex",
//     "flex-direction": "row-inverse",
//     position: "fixed",
//     bottom: theme.spacing.unit * 2,
//     right: theme.spacing.unit * 2,
//     backgroundColor: green[500],
//     color: theme.palette.common.white
//   }

// });
const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "rgb(2, 128, 128)"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    type: "light"
  },
  typography: {
    useNextVariants: true
  }
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    store.set("sfarms", {});
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  async componentDidMount() {
    window.onscroll = function () {
      // if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
      //   document.getElementById("hmBtn").style.display = "flex";
      // } else {
      //   document.getElementById("hmBtn").style.display = "none";
      // }
    };

    let proDetails = await ProService.getProDetails();
    if (proDetails && proDetails.data) {
      store.set("sfarms", proDetails.data);
    }
  }
  onHandleClick = () => {
    window.open("https://wa.me/918019503344", "_blank");
  }
  render() {
    //const { classes } = this.props;
    return (
      <BrowserRouter>
        <React.Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              SFarmsIndia | Agriculture Land Marketplace | Land Services
            </title>
            <meta
              name="description"
              //         content="A peer to peer marketplace to buy & sell agricultural lands or farm lands. The simplest way to find land for sale, farms for sale and acres for sale.
              // "
              content="Buy and Sell agriculture land or farm land. The simplest way to find land for sale, farm for sale and agri industrial lands."
            />
          </Helmet>
          <Button
            id="hmBtn"
            variant="fab"
            aria-label="Delete"
            // className={classes.fab}
            style={{
              display: "flex",
              "flex-direction": "row-inverse",
              position: "fixed",
              bottom: theme.spacing.unit * 8,
              right: theme.spacing.unit * 1,
              backgroundColor: green[500],
              color: theme.palette.common.white,
            }}
            // component={Link}
            // to="/"
            onClick={this.onHandleClick}
          >
            {/* <HomeIcon />
             */}
            <img
              src={images["whatsapp2.png"]}
              alt="whatsApp"
              width="35"
              height="35"
              // className={classes.icon2}
              // style={{ height: "2.5em" }} 
            />{" "}
          </Button>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Root />
          </MuiThemeProvider>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
