import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";


function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
      return 0;
    });
    return images;
  }
  
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg|pdf)$/)
  );
  
  const styles = theme => ({
    root: theme.mixins.gutters({
      margin: 3,
      //paddingTop: "1%",
      paddingBottom: "3%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    }),
    paper: {
      //margin: 1,
      paddingTop: "1%",
      paddingBottom: "1%",
      paddingLeft: "1%",
      paddingRight: "1%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    },
    text: {
      "line-height": "100%"
    },
    title: {
      "border-left": "solid #ff0000cc 5px",
      //'border-top': 'solid red 5px',
      padding: "1%",
      paddingTop: "1%",
      "background-color": "#f7f5f5"
    },
    autoGrid: {
      padding: theme.spacing.unit * 2
    }
  });
class DPRPreparation extends Component {
    constructor(props) {
      super(props);
      this.state = {
      }
    }

    render(){
        const { classes } = this.props;
        return(
            <React.Fragment>
            <Helmet>
              <title>DPR Preparation | SFarmsIndia.com</title>
              {/* <meta
                name="description"
                content="SFarmsIndia is the first Agri Land Marketplace in India. It was established for the sole purpose of enabling farmers to sell Agriculture Lands and Eco Farms.
                "
              /> */}
            </Helmet>
            <div className={classes.root}>
            <Typography className={classes.title} variant="title" gutterBottom>
            <b>DPR Preparation</b>
              </Typography>
              <Paper className={classes.paper} elevation={6}>
                        <Typography align="left" variant="title">
                        Detailed Project Report (DPR) for Bio-CNG Projects
                        </Typography>
                        <Typography component="p" align="justify">
                            <p>
                            At SFarmsIndia, we specialize in creating comprehensive Detailed Project Reports (DPR) for
your Bio-CNG projects. Our reports are tailored to your specific needs, ensuring you have all the
essential details to move forward with confidence. Whether you’re starting a new plant or
expanding an existing one, our DPR provides a clear roadmap for your project’s success.                 </p>
<Typography variant="title" align="left">
What We Include in Your DPR:
                        </Typography>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Project Overview:</b> A clear outline of your project’s goals, scope, and timeline, setting
the foundation for success.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Technology &amp; Process:</b> A breakdown of the feedstock-to-gas conversion, biogas
production, and CNG compression technologies involved in your plant.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Market &amp; Demand Analysis:</b> Insights into market opportunities and demand for Bio-
CNG in your area, ensuring your plant is aligned with industry needs.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Location &amp; Site Evaluation:</b> Assessment of the chosen location, including feedstock
availability, transport access, and site suitability.</li>
                                {/* <br /> */}


                            </ul>
                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Financial Plan:</b> A detailed breakdown of capital investment (CAPEX), operational costs
(OPEX), revenue projections, and expected ROI to help you understand your financial
commitment and returns.</li>
                                {/* <br /> */}


                            </ul>
                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Regulatory Compliance:</b> An overview of the permits and legal requirements necessary
to ensure your project meets environmental and safety standards.</li>
                                {/* <br /> */}


                            </ul>
                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Risk Mitigation:</b> Identification of potential risks and strategies to manage them, ensuring
smooth project execution.</li>
                                {/* <br /> */}


                            </ul>
                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Project Timeline:</b> A clear, actionable timeline with key milestones, from site preparation
to plant commissioning.</li>
                                {/* <br /> */}


                            </ul>
                        

                        <Typography variant="title" align="left">
                        Why You Need a DPR:
                        </Typography>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Secures Funding:</b> A well-detailed DPR is crucial for attracting investors and securing
loans.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Clear Planning:</b> It serves as a roadmap, guiding you through every phase of your Bio-
CNG project.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Regulatory Approval:</b> Ensures compliance with legal and environmental standards,
helping you navigate required permits.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Risk Management:</b> Identifies challenges early and offers strategies to mitigate risks.</li>
                                {/* <br /> */}


                            </ul>
                           
                            </Typography>

                        <Typography component="p" align="justify">
                            <p>
                            With our expertise in Bio-CNG projects, SFarmsIndia ensures your project’s feasibility is well-documented, setting you up for long-term success. Partner with us to create a Detailed Project
Report that brings your Bio-CNG vision to life, from concept to completion.
                            </p>
                            
                        </Typography>
                        

                        
                    </Paper>
            </div>
          </React.Fragment>
        );
    }
    }

    DPRPreparation.propTypes = {
        classes: PropTypes.object.isRequired
      };
      
      export default withStyles(styles)(DPRPreparation);
