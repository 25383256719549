
import store from "store";


const MINUTES_UNITL_AUTO_LOGOUT = 20 // in mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY = 'lastAction';


function getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY), 10);
}
function setLastAction(lastAction) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
}
export function initListener() {
    document.body.addEventListener('click', () => reset());
    document.body.addEventListener('mouseover', () => reset());
    document.body.addEventListener('mouseout', () => reset());
    document.body.addEventListener('keydown', () => reset());
    document.body.addEventListener('keyup', () => reset());
    document.body.addEventListener('keypress', () => reset());
}
function reset() {
    setLastAction(Date.now());
}
export function initInterval() {
    setInterval(() => {
        check();
    }, CHECK_INTERVAL);
}
export function check() {
    const now = Date.now();
    const timeleft = getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (isTimeout) {
        // alert('logout'); // Call here logout function, expire session
        if (store.get("user")) {
            store.remove("user");
        }

        //localStorage.clear();
    }
}