import React from "react";
// import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
// import Select from "@material-ui/core/Select";

// import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import List from "@material-ui/core/List";
// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";

// import PersonRounded from "@material-ui/icons/PersonRounded";
// import MenuItem from "@material-ui/core/MenuItem";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";

import store from "store";
// import Divider from "@material-ui/core/Divider";
import AccountCircle from "@material-ui/icons/AccountCircle";

import logo from "./images/newmainlogo.webp";
// import Login from "./images/Login.png";

import LoginSvg from "./Icons/LoginSvg";
import { logOut } from "../api/UserService";

const styles = theme => ({
  logo: {
    position: "sticky",
    top: 0,
    zIndex: 99,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    padding: "1%",
    backgroundColor: "#fff"
    //paddingLeft: "3%"
  },
  MYtitle: {
    color: "#f50057",
    "text-decoration-line": "none"
  },
  FARMStitle: {
    color: "rgba(0, 0, 0, 0.54)",
    "text-decoration-line": "none"
  },
  INDIAtitle: {
    color: "#009494",

    "text-decoration-line": "none"
  },
  caption: {
    color: "black",
    flex: 1,
    "margin-top": "2%",
    "text-decoration-line": "none"
  },
  button: {
    color: "rgb(2, 128, 128)",
    fontSize: "71%",
    padding: "4px 8px",
    
    //width: "31%",
    //margin: "1%",
    // float: "right"
    // textTransform: "capitalize"
  },
  button1: {
    // color: "rgb(2, 128, 128)",
    fontSize: "71%",
    padding: "1px 8px"
    //width: "31%",
    //margin: "1%",
    // float: "right"
    // textTransform: "capitalize"
  },
  list: {
    " &:focus": {
      "background-color": "LightSeaGreen"
    }
  },
  icon: {
    marginRight: "1%",
    whiteSpace: "nowrap"

    // "text-align": "center"
  }
});

class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      menuOpen: null
    };
  }
  handleLogOut = async () => {
    await logOut();
    store.remove("user");
    store.remove("sfarms");
    window.location.replace("/");
  };
  handleClickOpen = event => {
    // this.setState({
    //   menuOpen: true
    // });
    this.setState({ menuOpen: event.currentTarget });
  };
  handleClose = event => {
    this.setState({
      menuOpen: null
    });
  };

  render() {
    const { classes } = this.props;
    // const { anchorEl, setAnchorEl } = this.state;
    // const open = Boolean(anchorEl);
    return (
      <div className={classes.logo}>
        <Grid container wrap="nowrap" direction="row" alignItems="center">
          <Grid item xs>
            <Link to="/">
              <img 
                style={{ flex: 1}}
                src={logo}
                alt="Logo"
                width="160"
                height="50"
              />
            </Link>
          </Grid>
          { !this.props.user ? (
            <React.Fragment>
              <Grid item style={{marginTop:"3%"}}>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  component={Link}
                  to="/register"
                  className={classes.button}
                  style={{color:"#FFFFFF"}}
                >
                  {/* <PersonAddRounded size="small"/>
                  &nbsp;&nbsp; */}
                  <b>sign up</b>
                </Button>
              </Grid>

              <Grid item alignItems="center" style={{marginTop:"3%",marginLeft:"2%"}}>
                {/* <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  component={Link}
                  to="/login"
                  className={classes.button} */}
                {/* > */}
                {/* <PersonAddRounded size="small"/>
                  &nbsp;&nbsp; */}
                {/* {" Login "}
                </Button> */}
                <IconButton
                  variant="outlined"
                  size="medium"
                  color="secondary"
                  component={Link}
                  to="/login"
                  className={classes.button1}
                >
                  {/* <img loading="lazy" 
                    style={{
                      flex: 1,
                      height: "25px",
                      width: "25px",
                      // paddingRight: "4%"
                    }}
                    src={Login}
                    alt="Login"
                  /> */}
                  <LoginSvg fontSize="large" />
                  <span style={{"fontFamily": "Helvetica",marginTop:"-2%"}}><b>LOGIN</b></span>
                  {/* <Typography  color="primary" style={{fontWeight:500}}>LOGIN</Typography> */}
                </IconButton>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item>
                <IconButton
                  Button
                  size="small"
                  color="primary"
                  component={Link}
                  to="/profile"
                >
                  <AccountCircle fontSize="medium" />
                </IconButton>
              </Grid>

              <Grid item alignItems="center">
                <IconButton
                  variant="outlined"
                  size="medium"
                  color="secondary"
                  onClick={this.handleLogOut}
                >
                  <PowerSettingsNew fontSize="medium" />
                </IconButton>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Logo);
