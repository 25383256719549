import axios from "./baseAxios";
// import store from "store";

// import APIs from "../constants";

// const hostServerAddress = APIs.BASE_URL;
// const port = APIs.PORT;

export async function getUsersData(user) {
  try {
    const response = await axios.get("/users/findall?phone_no=" + user);
    if (response.data.statusCode === 200) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    return error;
  }
}

export async function getUsersDataByID(id) {
  try {
    const response = await axios.get("/users?id=" + id);
    if (response.data.statusCode === 200) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    return error;
  }
}

export async function getUserDetailsByID(id) {
  try {
    const response = await axios.get("/users/details/" + id);
    if (response.data.statusCode === 200) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    return error;
  }
}
export async function getUsers(id) {
  try {
    const response = await axios.get("/users?id=" + id);
    // console.log('==========GET Users Response==========================');
    // console.log(response);
    // console.log('====================================');
    if (response.data.statusCode === 200) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    console.log("==============GET Users Error======================");
    console.log(error);
    console.log("====================================");
    return error;
  }
}


export async function putUserIdentity(user, id) {
  try {
    const response = await axios.put("/users/" + id, user);

    if (response.data.statusCode === 201) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    return error;
  }
}

export async function forgotPasswordSendOtp(obj) {
  try {
    const response = await axios.post("/users/forgotPassword/sendotp", obj);

    if (response.data.statusCode === 201) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    return error;
  }
}
export async function forgotPassword(obj) {
  try {
    const response = await axios.post("/users/forgotPassword", obj);

    if (response.data.statusCode === 201) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    return error;
  }
}

export async function logOut() {
  try {
    const response = await axios.get("/users/logout");

    if (response.data.statusCode === 200) {
      return response;
    } else {
      throw new Error(response.data.description);
    }
  } catch (error) {
    return error;
  }
}
