import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";


function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
      return 0;
    });
    return images;
  }
  
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg|pdf)$/)
  );
  
  const styles = theme => ({
    root: theme.mixins.gutters({
      margin: 3,
      //paddingTop: "1%",
      paddingBottom: "3%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    }),
    paper: {
      //margin: 1,
      paddingTop: "1%",
      paddingBottom: "1%",
      paddingLeft: "1%",
      paddingRight: "1%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    },
    text: {
      "line-height": "100%"
    },
    title: {
      "border-left": "solid #ff0000cc 5px",
      //'border-top': 'solid red 5px',
      padding: "1%",
      paddingTop: "1%",
      "background-color": "#f7f5f5"
    },
    autoGrid: {
      padding: theme.spacing.unit * 2
    }
  });
class FeedStockManagement extends Component {
    constructor(props) {
      super(props);
      this.state = {
      }
    }

    render(){
        const { classes } = this.props;
        return(
            <React.Fragment>
            <Helmet>
              <title>FeedStock Management | SFarmsIndia.com</title>
              {/* <meta
                name="description"
                content="SFarmsIndia is the first Agri Land Marketplace in India. It was established for the sole purpose of enabling farmers to sell Agriculture Lands and Eco Farms.
                "
              /> */}
            </Helmet>
            <div className={classes.root}>
            <Typography className={classes.title} variant="title" gutterBottom>
            <b>FeedStock Management</b>
              </Typography>
              <Paper className={classes.paper} elevation={6}>
                        <Typography variant="title" align="left">
                        Feedstock Management for Bio-CNG Plants
                        </Typography>
                        <Typography component="p" align="justify">
                            <p>
                            At SFarmsIndia, we specialize in creating seamless connections between feedstock suppliers
and Bio-CNG plant operators. Our mission is to ensure that Bio-CNG plants have access to a
consistent, high-quality supply of feedstock, while providing feedstock producers with reliable
markets for their resources. Whether you&#39;re a farmer with agricultural residues or a business
handling organic waste, we connect you to Bio-CNG plants that can make the most of your
feedstock.                 </p>
<Typography variant="title" align="left">
How We Help:
                        </Typography>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Connecting the Right Suppliers with the Right Plants</b>
                                <br />
                                We take the guesswork out of finding the right feedstock for Bio-CNG production. By
understanding the specific needs of both Bio-CNG plants and feedstock suppliers, we
make sure that the right materials are delivered to the right places..</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Building a Reliable Supply Chain</b>
                                <br />
                                Consistency is key when it comes to running a Bio-CNG plant. Our services ensure that
feedstock suppliers can count on steady demand, while plant operators have a
dependable and uninterrupted supply of the resources they need to run efficiently.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Tailored Connections</b>
                                <br />
                                We know every Bio-CNG plant has its unique requirements, just as every supplier has
different offerings. That’s why we provide customized solutions, matching the best
suppliers with the best-fit Bio-CNG plants to meet production needs.</li>
                                


                            </ul>

                           
                           
                           
                        

                        

                            
                            
                           
                            </Typography>

                        <Typography component="p" align="justify">
                            <p>
                            With SFarmsIndia handling the connection between suppliers and Bio-CNG plants, we make the
process smoother and more efficient for everyone involved. By streamlining this supply chain,
we help promote the growth of renewable energy while providing a valuable and sustainable
market for feedstock suppliers.
                            </p>
                            
                        </Typography>
                        

                        
                    </Paper>
            </div>
          </React.Fragment>
        );
    }
    }

    FeedStockManagement.propTypes = {
        classes: PropTypes.object.isRequired
      };
      
      export default withStyles(styles)(FeedStockManagement);
