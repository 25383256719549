import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";


function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
      return 0;
    });
    return images;
  }
  
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg|pdf)$/)
  );
  
  const styles = theme => ({
    root: theme.mixins.gutters({
      margin: 3,
      //paddingTop: "1%",
      paddingBottom: "3%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    }),
    paper: {
      //margin: 1,
      paddingTop: "1%",
      paddingBottom: "1%",
      paddingLeft: "1%",
      paddingRight: "1%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    },
    text: {
      "line-height": "100%"
    },
    title: {
      "border-left": "solid #ff0000cc 5px",
      //'border-top': 'solid red 5px',
      padding: "1%",
      paddingTop: "1%",
      "background-color": "#f7f5f5"
    },
    autoGrid: {
      padding: theme.spacing.unit * 2
    }
  });
class FeasibilityStudy extends Component {
    constructor(props) {
      super(props);
      this.state = {
      }
    }

    render(){
        const { classes } = this.props;
        return(
            <React.Fragment>
            <Helmet>
              <title>Feasibility Study | SFarmsIndia.com</title>
              {/* <meta
                name="description"
                content="SFarmsIndia is the first Agri Land Marketplace in India. It was established for the sole purpose of enabling farmers to sell Agriculture Lands and Eco Farms.
                "
              /> */}
            </Helmet>
            <div className={classes.root}>
            <Typography className={classes.title} variant="title" gutterBottom>
            <b>Feasibility Study</b>
              </Typography>
              <Paper className={classes.paper} elevation={6}>
                        <Typography align="left" variant="title">
                        Project Feasibility Studies for Bio-CNG Ventures
                        </Typography>
                        <Typography component="p" align="justify">
                            <p>
                            At SFarmsIndia, we conduct in-depth feasibility studies tailored to your Bio-CNG project. We
work with you to understand your specific needs, using key inputs like feedstock availability,
location, logistics, and more to assess the project’s potential. Our goal is to provide you with a
clear, data-driven analysis that empowers you to make informed decisions and maximize project
success.                 </p>
<Typography variant="title" align="left">
What We Consider:
                        </Typography>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Feedstock Availability:</b> We assess the types and sources of feedstock available for
your project to ensure sustainable production.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Location Suitability:</b> The right location makes all the difference. We analyze site factors
like access to feedstock, infrastructure, and environmental impact.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Logistics &amp; Transport:</b> Efficient transport is crucial, so we review routes, costs, and
logistics for both feedstock delivery and Bio-CNG distribution.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Regulatory Compliance:</b> We ensure your project meets all necessary legal and
environmental standards, avoiding potential setbacks.</li>
                                {/* <br /> */}


                            </ul>
                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Cost &amp; Financial Viability:</b> We provide a detailed cost breakdown, helping you
understand the financial investment required and expected returns.</li>
                                {/* <br /> */}


                            </ul>
                        </Typography>

                        <Typography component="p" align="justify">
                            <p>
                            At SFarmsIndia, we’re committed to helping you turn your idea into a successful, sustainable
venture.
                            </p>
                            
                        </Typography>
                        

                        
                    </Paper>
            </div>
          </React.Fragment>
        );
    }
    }

    FeasibilityStudy.propTypes = {
        classes: PropTypes.object.isRequired
      };
      
      export default withStyles(styles)(FeasibilityStudy);
