import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";


function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
      return 0;
    });
    return images;
  }
  
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg|pdf)$/)
  );
  
  const styles = theme => ({
    root: theme.mixins.gutters({
      margin: 3,
      //paddingTop: "1%",
      paddingBottom: "3%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    }),
    paper: {
      //margin: 1,
      paddingTop: "1%",
      paddingBottom: "1%",
      paddingLeft: "1%",
      paddingRight: "1%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    },
    text: {
      "line-height": "100%"
    },
    title: {
      "border-left": "solid #ff0000cc 5px",
      //'border-top': 'solid red 5px',
      padding: "1%",
      paddingTop: "1%",
      "background-color": "#f7f5f5"
    },
    autoGrid: {
      padding: theme.spacing.unit * 2
    }
  });
class FinancialConsultancy extends Component {
    constructor(props) {
      super(props);
      this.state = {
      }
    }

    render(){
        const { classes } = this.props;
        return(
            <React.Fragment>
            <Helmet>
              <title>Financial Consultancy | SFarmsIndia.com</title>
              {/* <meta
                name="description"
                content="SFarmsIndia is the first Agri Land Marketplace in India. It was established for the sole purpose of enabling farmers to sell Agriculture Lands and Eco Farms.
                "
              /> */}
            </Helmet>
            <div className={classes.root}>
            <Typography className={classes.title} variant="title" gutterBottom>
            <b>Financial Consultancy</b>
              </Typography>
              <Paper className={classes.paper} elevation={6}>
                        <Typography variant="title" align="left">
                        Financial Consultancy for Bio-CNG Projects
                        </Typography>
                        <Typography component="p" align="justify">
                            <p>
                            At SFarmsIndia, we offer comprehensive financial consultancy services to help you successfully
launch and manage your Bio-CNG project. Our team provides tailored support in navigating the
financial complexities of your venture, ensuring a sustainable and profitable outcome.              </p>
<Typography variant="title" align="left">
Our Services Include:
                        </Typography>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Feasibility & Financial Planning:</b> We assess the viability of your Bio-CNG project and
help you create a solid financial plan, covering everything from budgeting to projections.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Funding Guidance:</b> Our experts will assist you in finding the right funding sources,
whether through loans, grants, or investments.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Risk & Cost Management:</b> We identify potential risks and provide strategies to optimize
costs, ensuring your project remains financially healthy.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Regulatory Support:</b> We guide you through the necessary regulations and help you
take advantage of tax incentives and government programs.</li>
                                {/* <br /> */}


                            </ul>
                           
                           
                        

                        <Typography variant="title" align="left">
                        Why Choose Us?
                        </Typography>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Expertise:</b> Our team has experience in Bio-CNG financial planning and understands the
challenges unique to renewable energy projects.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Tailored Support:</b> We provide end-to-end assistance, from initial planning to securing
funds and managing operations.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Proven Success:</b> We’ve helped many clients secure the financial backing they need to
make their Bio-CNG projects a reality.</li>
                                {/* <br /> */}


                            </ul>

                           
                           
                            </Typography>

                        <Typography component="p" align="justify">
                            <p>
                           Let us help you turn your Bio-CNG vision into a successful, sustainable project. Contact us
today to get started!
                            </p>
                            
                        </Typography>
                        

                        
                    </Paper>
            </div>
          </React.Fragment>
        );
    }
    }

    FinancialConsultancy.propTypes = {
        classes: PropTypes.object.isRequired
      };
      
      export default withStyles(styles)(FinancialConsultancy);
