//import React from "react";
import React, { Suspense } from 'react';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
//import HomeScreen from "./screens/HomeScreen";
//import TradeScreen from "./screens/LandContractTradeScreen";
//import ProfileScreen from "./screens/ProfileScreen";
//import LoginScreen from "./screens/LoginScreen";
//import LoginScreen1 from "./screens/LoginScreen1";
//import RegisterScreen from "./screens/RegisterScreen";
//import AgriLandList from "./listings/AgriLandList";
//import CollectiveFarmList from "./listings/CollectiveFarmList";
//import EcoFarmsList from "./listings/EcoFarmsList";
//import AgriLandDetails from "./screens/AgriLandDetails";
//import EstateLandDetails from "./screens/EstateLandDetails";

//import SellingScreen from "./screens/AddListing";
//import BuyersGuide from "./screens/BuyersGuide";
//import AgriLandSellForm from "../components/sellForms/AgriLandSellForm";
//import CollectiveFarmsSellForm from "../components/sellForms/CollectiveFarmsSellForm";
//import EcoFarmsSellForm from "../components/sellForms/EcoFarmsSellForm";
//import EstateSellForm from "../components/sellForms/EstateSellForm";

//import LandContractSellForm from "../components/sellForms/LandContractSellForm";
//import LandContractTradeScreen from "./screens/LandContractTradeScreen";
//import AgriLandContactSeller from "./AgriLandContactSeller";
// import store from "store";
//import ProductScreenEdit from "../components/SellingFormEdit/ProductScreenEdit";
//import AgriProductScreenEdit from "../components/SellingFormEdit/AgriProductScreenEdit";
//import EstateProductScreenEdit from "../components/SellingFormEdit/EstateProductScreenEdit";
//import EcoProductScreenEdit from "../components/SellingFormEdit/EcoProductScreenEdit";
import PrivateRoute from "./PrivateRoute";
//import ClientKYCScreen from "./screens/ClientKYCScreen";


//import PrivacyPolicy from "../components/footer/PrivacyPolicy";
// import NotFound from "./NotFound";

// import Faq from "../components/footer/Faq";
// import Cookie from "../components/footer/Cookie";
// import Wallet from "./profile/Wallet";
import {
  check,
  initInterval,
  initListener
} from "../utils/sessionManagement/SessionIntervel";
//import EcoFarmsDetails from "./screens/EcoFarmsDetails";
//import CollectiveFarmDetails from "./screens/CollectiveFarmDetails";
//import MyOrdersScreen from "./screens/MyOrdersScreen";
//import LandContractsDetails from "./screens/LandContractsDetails";
//import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
//import MyListings from "../components/mylistings/MyListings";
import Header from "./Header";
//import EstateLandList from "./listings/EstateLandList";
//import ExcelSheet from "./Compare/ExcelSheet";
//import BuyerPlans from "./Compare/BuyerPlans";
//import RefundPolicy from "./footer/RefundPolicy";
//import ResponseScreen from "./screens/ResponseScreen";
// import Developers from "./Compare/Developers";
// import LandSearch from "./Compare/LandSearch";
// import LandLaws from "./Compare/LandLaws";
// import CheckList from "./Compare/CheckList";
import store from "store";
import NapierGrassPropagation from './Compare/NapierGrassPropagation';
import FeasibilityStudy from './Compare/FeasibilityStudy';
import DPRPreparation from './Compare/DPRPreparation';
import TechnologyConsultancy from './Compare/TechnologyConsultancy';
import FeedStockManagement from './Compare/FeedStockManagement';
import FinancialConsultancy from './Compare/FinancialConsultancy';
import BioCNGToken from './Compare/BioCNGToken';

const EcoFarmsDetails = React.lazy(() => import('./screens/EcoFarmsDetails'));
const PriceChartScreen = React.lazy(() => import('./screens/PriceChartScreen')); 
const CarbonFarmCreditChartScreen = React.lazy(() => import('./screens/CarbonFarmCreditChartScreen')); 
const MyOrdersScreen = React.lazy(() => import('./screens/MyOrdersScreen'));
const CollectiveFarmDetails = React.lazy(() => import('./screens/CollectiveFarmDetails'));
const AgriLandContactSeller = React.lazy(() => import('./AgriLandContactSeller'));
const AgriLandList = React.lazy(() => import('./listings/AgriLandList'));
const CollectiveFarmList = React.lazy(() => import('./listings/CollectiveFarmList'));
const EcoFarmsList = React.lazy(() => import('./listings/EcoFarmsList'));
const AgriLandDetails = React.lazy(() => import('./screens/AgriLandDetails'));
const EstateLandDetails = React.lazy(() => import('./screens/EstateLandDetails'));
const SellingScreen = React.lazy(() => import('./screens/AddListing'));
const BuyersGuide = React.lazy(() => import('./screens/BuyersGuide'));
const RegisterScreen =  React.lazy(() => import('./screens/RegisterScreen'));
const LoginScreen1 = React.lazy(() => import('./screens/LoginScreen1'));
const LoginScreen = React.lazy(() => import('./screens/LoginScreen'));
const ProfileScreen = React.lazy(() => import('./screens/ProfileScreen'));
const TradeScreen = React.lazy(() => import("./screens/LandContractTradeScreen"));
const CFCTradeScreen = React.lazy(() => import("./screens/CarbonFarmCreditTradeScreen"));
const ReservedFarmScreen = React.lazy(() => import("./screens/ReservedFarmScreen"));
const HomeScreen = React.lazy(() => import('./screens/HomeScreen'));
const ProductScreenEdit = React.lazy(() => import('../components/SellingFormEdit/ProductScreenEdit'));
const AgriProductScreenEdit = React.lazy(() => import('../components/SellingFormEdit/AgriProductScreenEdit'));
const EstateProductScreenEdit = React.lazy(() => import('../components/SellingFormEdit/EstateProductScreenEdit'));
const EcoProductScreenEdit = React.lazy(() => import('../components/SellingFormEdit/EcoProductScreenEdit'));
const ClientKYCScreen = React.lazy(() => import('./screens/ClientKYCScreen'));
const ForgotPasswordScreen = React.lazy(() => import('./screens/ForgotPasswordScreen'));
const ResponseScreen = React.lazy(() => import('./screens/ResponseScreen'));
const BuyerPlans = React.lazy(() => import('./Compare/BuyerPlans'));
const AboutUs = React.lazy(() => import('../components/footer/AboutUs'));
const Terms = React.lazy(() => import('../components/footer/Terms'));
const PrivacyPolicy = React.lazy(() => import('../components/footer/PrivacyPolicy'));
const NotFound = React.lazy(() => import('./NotFound'));
const Faq = React.lazy(() => import('../components/footer/Faq'));
const FaqAdvance = React.lazy(() => import('../components/footer/faqAdvance'));
const Cookie = React.lazy(() => import('../components/footer/Cookie'));
const Wallet = React.lazy(() => import('./profile/Wallet'));
const RefundPolicy = React.lazy(() => import('./footer/RefundPolicy'));
const MyListings = React.lazy(() => import('../components/mylistings/MyListings'));
const EstateLandList = React.lazy(() => import('./listings/EstateLandList'));
const ExcelSheet = React.lazy(() => import('./Compare/ExcelSheet'));
const Developers = React.lazy(() => import('./Compare/Developers'));
const LandSearch = React.lazy(() => import('./Compare/LandSearch'));
const LandLaws = React.lazy(() => import('./Compare/LandLaws'));
const CheckList = React.lazy(() => import('./Compare/CheckList'));



const styles = {
  root: {
    width: "100%"
  }
};

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn : false
    };
    check();
    initListener();
    initInterval();
    if(store.get("user") !== undefined) {
      this.setState({ isLoggedIn: true});
    }
    this.onChange = this.onChange.bind(this);
  }

  onChange(isLogged)
  {   
    this.setState({ isLoggedIn: isLogged });
  };

  componentDidMount = () => {
    if(store.get("user") !== undefined) {
      this.setState({ isLoggedIn: true});
    }
    // store.watch("user", function() {
    //   if (store.get("user") === null) {
    //     window.location.replace("/");
    //   }
    // });
  };
  render() {
    return (
      <React.Fragment>
        <Header user={this.state.isLoggedIn}/>
        <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/land-fractions/trade" component={TradeScreen} />
          <Route exact path="/carbon-farm-credits/trade" component={CFCTradeScreen} />
          <Route exact path="/reservefarm/product/:id" component={ReservedFarmScreen} />
          <PrivateRoute exact path="/sell" component={SellingScreen} />
          <Route exact path="/bio-cng" component={BuyersGuide} />
          <PrivateRoute exact path="/profile" component={ProfileScreen} />
          <Route exact path="/pro-details" component={ExcelSheet} />
          <Route exact path="/buyer-plans" component={BuyerPlans} />
          <Route exact path="/developers" component={Developers} />
          <Route exact path="/land-search" component={LandSearch} />
          <Route exact path="/land-laws" component={LandLaws} />
          <Route exact path="/check-list" component={CheckList} />
          <Route exact path="/bio-cng/napier-grass" component={NapierGrassPropagation} />
          <Route exact path="/bio-cng/feasibility-study" component={FeasibilityStudy} />
          <Route exact path="/bio-cng/dpr-preparation" component={DPRPreparation} />
          <Route exact path="/bio-cng/technology-consultancy" component={TechnologyConsultancy} />
          <Route exact path="/bio-cng/feedstock-management" component={FeedStockManagement} />
          <Route exact path="/bio-cng/financial-consultancy" component={FinancialConsultancy} />
          <Route exact path="/bio-cng/token" component={BioCNGToken} />
          <Route
              exact
              path="/price-trend"
              component={PriceChartScreen}
            />
            <Route
              exact
              path="/price-chart"
              component={CarbonFarmCreditChartScreen}
            />
          <PrivateRoute
            exact
            path="/transaction-successful"
            component={ResponseScreen}
          />
          <Route exact path="/register" component={RegisterScreen} />
          <Route
  path='/login'
  render={(props) => (
    <LoginScreen {...props} onLoggedIn={this.onChange} />
  )}
/>

          {/* <Route exact path="/login" component={LoginScreen} onCountChange={this.onChange}/> */}
          <Route exact path="/login-pro" component={LoginScreen1} />
          <Route exact path="/resetpassword" component={ForgotPasswordScreen} />
          {/* <PrivateRoute
            exact
            path="/agriland-sellform"
            component={AgriLandSellForm}
          /> */}
          <PrivateRoute
            exact
            path="/profile/kyc-form"
            component={ClientKYCScreen}
          />

          {/* <PrivateRoute
            exact
            path="/collective-farms-sellform"
            component={CollectiveFarmsSellForm}
          />
          <PrivateRoute
            exact
            path="/eco-farms-sellform"
            component={EcoFarmsSellForm}
          />
          <PrivateRoute
            exact
            path="/estateland-sellform"
            component={EstateSellForm}
          /> */}
          {/* <PrivateRoute
            exact
            path="/land-contract-sellform"
            component={LandContractSellForm}
          />
          <PrivateRoute
            exact
            path="/land-contract-trade"
            component={LandContractTradeScreen}
          />

          */}
          {/* <PrivateRoute exact path="/avail-service/:name?/:id" component={LandServices} /> */}
          <PrivateRoute exact path="/profile/wallet" component={Wallet} />
          <PrivateRoute
            exact
            path="/trade/myorders"
            component={MyOrdersScreen}
          /> 
          <PrivateRoute
            exact
            path="/profile/my-listings/:id"
            component={MyListings}
          />
          <PrivateRoute
            exact
            path="/mylistings/product/:id"
            component={ProductScreenEdit}
          />
          <PrivateRoute
            exact
            path="/csa/search/product/:id"
            component={ProductScreenEdit}
          />
          <PrivateRoute
            exact
            path="/agri/mylistings/product/:id"
            component={AgriProductScreenEdit}
          />
          <PrivateRoute
            exact
            path="/agri/search/product/:id"
            component={AgriProductScreenEdit}
          />
          <PrivateRoute
            exact
            path="/services/mylistings/product/:id"
            component={EcoProductScreenEdit}
          />
          <PrivateRoute
            exact
            path="/eco/search/product/:id"
            component={EcoProductScreenEdit}
          />
          <PrivateRoute
            exact
            path="/estate/mylistings/product/:id"
            component={EstateProductScreenEdit}
          />
          <PrivateRoute
            exact
            path="/estate/search/product/:id"
            component={EstateProductScreenEdit}
          />
          <PrivateRoute exact path="/pro-details" component={ExcelSheet} />
          <Route exact path="/agri-lands" component={AgriLandList} />
          <Route exact path="/agri-lands/agricultural-land-for-sale-in-:thing?/:district?" component={AgriLandList} />
          {/* <Route exact path="/agri-lands/:state" component={AgriLandList} /> */}
          <Route exact path="/agri-lands/:desc" component={AgriLandDetails} />
          <PrivateRoute
            exact
            path="/agri-lands/:desc/contact-seller-form"
            component={AgriLandContactSeller}
          />
          <Route exact path="/agri-industrial-lands" component={EcoFarmsList} />
          <Route exact path="/agri-industrial-lands/agri-industrial-land-for-sale-in-:thing?/:district?" component={EcoFarmsList} />

          {/* <Route exact path="/eco-farms/:state" component={EcoFarmsList} /> */}
          <Route exact path="/agri-industrial-lands/:desc" component={EcoFarmsDetails} />

          <Route
            exact
            path="/sustainable-farms"
            component={CollectiveFarmList}
          />
            <Route exact path="/sustainable-farms/land-fractions-for-sale-in-:thing?/:district?" component={CollectiveFarmList} />

          {/* <Route
          exact
          path="/collective-farms/:state"
          component={CollectiveFarmList}
        /> */}
          <Route
            exact
            path="/sustainable-farms/:desc"
            component={CollectiveFarmDetails}
          />
          {/* <Route exact path="/land-contracts" component={LandContractList} /> */}
          {/* <Route
          exact
          path="/land-contracts/:state"
          component={LandContractList}
        /> */}
          {/* <PrivateRoute
            exact
            path="/land-contracts/:desc"
            component={LandContractsDetails}
          /> */}
          <Route exact path="/estate-lands" component={EstateLandList} />
          <Route exact path="/estate-lands/estate-lands-for-sale-in-:thing?/:district?" component={EstateLandList} />
          <Route
            exact
            path="/estate-lands/:desc"
            component={EstateLandDetails}
          />
          {/* <Route
          exact
          path="/land-contracts/:state"
          component={LandContractList}
        /> */}
          <Route path="/about" component={AboutUs} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/faq" component={Faq} />
          <Route path="/faq-advanced" component={FaqAdvance} />
          <Route path="/cookie" component={Cookie} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/terms" component={Terms} />
          <Route path="*" component={NotFound} />
        </Switch>
        </Suspense>
      </React.Fragment>
    );
  }
}

Body.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Body);
