var ENV = process.env.NODE_ENV || "development";

var host = "http://localhost:";
var port = 5742;

function getAPIUrlConstants() {
  var obj = {
    production: {
      BASE_URL: "https://api.sfarmsindia.com",
      PORT: "",
      NO_IMAGE_URL : "https://api.sfarmsindia.com/images/product/noimage",
      IMAGE_URL: "https://api.sfarmsindia.com/images/product/",
      PAN_IMAGE: "https://api.sfarmsindia.com/images/pancard/",
      SOCKET_URL: "https://api.sfarmsindia.com"
    },
    development: {
      BASE_URL: host + port,
      PORT: port,
      NO_IMAGE_URL : host + port + "/images/product/noimage",
      IMAGE_URL: host + port + "/images/product/",
      PAN_IMAGE: host + port + "/images/pancard/",
      SOCKET_URL: host + port
    }
  };
  return obj[ENV];
}

export default getAPIUrlConstants();
