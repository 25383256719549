import React,{ Suspense } from "react";
//import BottomMenu from "./BottomMenu";
import Body from "./Body";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";


// const Body = React.lazy(() => import('./Body'));
const BottomMenu = React.lazy(() => import('./BottomMenu'));


const styles = theme => ({
  root: { width: "100%" },
  body: {
    marginBottom: "22%",
    backgroundColor: "#FFFFFF"
  }
});

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    
  }
  
  render() {
    const { classes } = this.props;
    
    
    return (
      // <Suspense fallback={<div>Loading...</div>}>
      <Grid
        container
        className={classes.root}
        direction="column"
        alignItems="stretch"
        spacing={0}
      >
        <Grid item xs className={classes.body}>
          <Body />
        </Grid>
        <Grid item xs>
        <Suspense fallback={<div>Loading...</div>}>
          <BottomMenu />
          </Suspense>
        </Grid>
      </Grid>
      // </Suspense>
    );
  }
}

Root.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Root);
