import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const LoginSvg = props => (
  <SvgIcon {...props}>
    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 95.63 92.7"
      {...props}
    >
      <defs>
        <style>{".prefix_logi__cls-1{fill:#777}.prefix_logi__cls-3{fill:#f50057}"}</style>
      </defs>
      <title>{"i-6"}</title>
      <path
        className="prefix_logi__cls-1"
        d="M57.25 66.13H42.84v-2h12.41V54.7h2v11.43z"
      />
      <path d="M24.8 17.39l-3.82-2H19.8v50.93l23.87 11V27.43z" fill="#028080" />
      <path
        className="prefix_logi__cls-1"
        d="M20.67 15.39l3.74 2h30.85v9.63h2V15.39H20.67z"
      />
      <path
        className="prefix_logi__cls-3"
        d="M57.89 50.74V31.25l-10.83 9.74 10.83 9.75z"
      />
      <path
        className="prefix_logi__cls-3"
        d="M63.22 31.43h6.09v17.25a1.88 1.88 0 0 1-1.88 1.88H65.1a1.88 1.88 0 0 1-1.88-1.88V31.43z"
        transform="rotate(-90 66.27 40.99)"
      />
    </svg>
  </SvgIcon>
);

export default LoginSvg;
