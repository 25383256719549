import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";


function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
      return 0;
    });
    return images;
  }
  
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg|pdf)$/)
  );
  
  const styles = theme => ({
    root: theme.mixins.gutters({
      margin: 3,
      //paddingTop: "1%",
      paddingBottom: "3%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    }),
    paper: {
      //margin: 1,
      paddingTop: "1%",
      paddingBottom: "1%",
      paddingLeft: "1%",
      paddingRight: "1%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    },
    text: {
      "line-height": "100%"
    },
    title: {
      "border-left": "solid #ff0000cc 5px",
      //'border-top': 'solid red 5px',
      padding: "1%",
      paddingTop: "1%",
      "background-color": "#f7f5f5"
    },
    autoGrid: {
      padding: theme.spacing.unit * 2
    }
  });
class TechnologyConsultancy extends Component {
    constructor(props) {
      super(props);
      this.state = {
      }
    }

    render(){
        const { classes } = this.props;
        return(
            <React.Fragment>
            <Helmet>
              <title>Technology Consultancy | SFarmsIndia.com</title>
              {/* <meta
                name="description"
                content="SFarmsIndia is the first Agri Land Marketplace in India. It was established for the sole purpose of enabling farmers to sell Agriculture Lands and Eco Farms.
                "
              /> */}
            </Helmet>
            <div className={classes.root}>
            <Typography className={classes.title} variant="title" gutterBottom>
            <b>Technology Consultancy</b>
              </Typography>
              <Paper className={classes.paper} elevation={6}>
                        <Typography align="left" variant="title">
                        Technology Consultancy for Bio-CNG Plants
                        </Typography>
                        <Typography component="p" align="justify">
                            <p>
                            At SFarmsIndia, we specialize in helping businesses navigate the complex world of Bio-CNG.
Our consultancy services are designed to support you through the entire process whether
you&#39;re starting a new Bio-CNG plant or looking to improve an existing one.                 </p>
<Typography variant="title" align="left">
What We Do:
                        </Typography>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Choosing the Right Digester Technology</b>
                                <br />
We help you select the best technology for your plant’s needs. Whether it&#39;s anaerobic
digesters or other fermentation systems, we ensure you choose a solution that works
best with your feedstock and production goals.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Designing Efficient Bio-CNG Plants</b>
                                <br />
We assist in designing a plant layout that maximizes efficiency. From how feedstock
moves through the system to ensuring the digester capacity is right for your needs, we
focus on getting the most out of every part of your plant.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Finding the Right Location</b>
                                <br />
The success of your plant starts with the right location. We help you evaluate potential
sites based on factors like proximity to feedstock sources, infrastructure availability, and
environmental impact to ensure your plant is set up for long-term success.</li>
                                


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Upgrading Biogas to Bio-CNG</b>
                                <br />
Once biogas is produced, it needs to be upgraded to meet Bio-CNG standards. We offer
advice on the best biogas upgrading systems, helping you choose the right solution to
purify the gas and maximize methane content.</li>
                                


                            </ul>
                           
                           
                        

                        <Typography variant="title" align="left">
                        Why Work With Us?
                        </Typography>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Industry Expertise:</b> We bring deep knowledge of Bio-CNG technologies to guide you
every step of the way.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Tailored Solutions:</b> We don’t offer one-size-fits-all answers; everything we do is
customized to your project’s needs.</li>
                                {/* <br /> */}


                            </ul>

                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}><b>Comprehensive Support:</b> From site evaluation to biogas upgrading, we offer end-to-
end consultancy to help you achieve optimal plant performance.</li>
                                {/* <br /> */}


                            </ul>

                            
                           
                            </Typography>

                        <Typography component="p" align="justify">
                            <p>
                            Let SFarmsIndia help you make informed decisions and ensure the success of your Bio-CNG
plant.
                            </p>
                            
                        </Typography>
                        

                        
                    </Paper>
            </div>
          </React.Fragment>
        );
    }
    }

    TechnologyConsultancy.propTypes = {
        classes: PropTypes.object.isRequired
      };
      
      export default withStyles(styles)(TechnologyConsultancy);
