import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";


function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
      return 0;
    });
    return images;
  }
  
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg|pdf)$/)
  );
  
  const styles = theme => ({
    root: theme.mixins.gutters({
      margin: 3,
      //paddingTop: "1%",
      paddingBottom: "3%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    }),
    paper: {
      //margin: 1,
      paddingTop: "1%",
      paddingBottom: "1%",
      paddingLeft: "1%",
      paddingRight: "1%",
      marginTop: theme.spacing.unit * 1,
  
      flexGrow: 1
    },
    text: {
      "line-height": "100%"
    },
    title: {
      "border-left": "solid #ff0000cc 5px",
      //'border-top': 'solid red 5px',
      padding: "1%",
      paddingTop: "1%",
      "background-color": "#f7f5f5"
    },
    autoGrid: {
      padding: theme.spacing.unit * 2
    }
  });
class NapierGrassPropagation extends Component {
    constructor(props) {
      super(props);
      this.state = {
      }
    }

    render(){
        const { classes } = this.props;
        return(
            <React.Fragment>
            <Helmet>
              <title>Napier Grass Propagation | SFarmsIndia.com</title>
              {/* <meta
                name="description"
                content="SFarmsIndia is the first Agri Land Marketplace in India. It was established for the sole purpose of enabling farmers to sell Agriculture Lands and Eco Farms.
                "
              /> */}
            </Helmet>
            <div className={classes.root}>
            <Typography className={classes.title} variant="title" gutterBottom>
           <b>Napier Grass Propagation</b> 
              </Typography>
              <Paper className={classes.paper} elevation={6}>
                        <Typography align="left" variant="title">
                           Napier Grass: A Sustainable Biomass Solution 
                        </Typography>
                        <Typography component="p" align="justify">
                            <p>
                                <b>Pennisetum purpureum</b> also known as <b>Napier grass</b>, is a high-yielding crop with significant
                                potential in biomass production. With its high cellulose content, it serves as an excellent source
                                of renewable energy and fuel. Its rapid growth and environmental benefits make it ideal for
                                sustainable agriculture.                  </p>
                            <b>North Region:</b>
                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}>Varieties: NB-21, FD-437, Pusa
                                    Giant Napier</li>
                                {/* <br /> */}


                            </ul>
                            <b>South Region:</b>
                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}>Varieties: CO-1, CO-2, CO-3, CO-4,
CO-5, Red Napier, APBN-1, BN
Hybrid</li>
                                {/* <br /> */}


                            </ul>
                            <b>West Region:</b>
                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}>Varieties: Suguna, Giant Napier,
Hybrid Napier-10 (HN-10), Yashwant
Hybrid (DHN-6), PBN-233, DHN-9</li>
                                {/* <br /> */}


                            </ul>
                            <b>East Region:</b>
                            <ul>
                                <li style={{ fontFamily: "Sans-serif" }}>Varieties: NB-21, IGFRI-10</li>
                                <br />


                            </ul>
                        </Typography>

                        <Typography variant="title" align="left">
                        Efficient Propagation for Optimal Growth
                        </Typography>
                        <Typography component="p" align="justify">
                            <p>
                            Though Napier grass can be grown from seeds, seed production is inconsistent. The most
reliable method is through stem cuttings from the plant’s stolons, which are planted along
furrows for uniform growth.
                            </p>
                            
                        </Typography>
                        <Typography variant="title" align="left">
                        Fast-Growing, Low-Maintenance, and High-Yielding
                        </Typography>
                        <Typography component="p" align="justify">
                            <p>Napier grass matures 4 to 6 times a year, thriving in resource-limited areas with minimal water
and nutrients. It improves soil fertility and prevents erosion. Typically yielding 30–40 tons per
hectare annually, some high-yielding varieties can produce more under optimal conditions. Its
lifespan is 5 to 8 years, with proper management extending productivity.</p>
                           
                        </Typography>
                        {/* <Typography component="p" align="justify">
                  
                </Typography> */}
                <Typography variant="title" align="left">
                A Commitment to Renewable Energy
                        </Typography>
                        <Typography component="p" align="justify">
                            <p>
                            Napier grass offers a sustainable solution for renewable energy. Its fast growth, low
maintenance, and high biomass output make it a valuable resource for biomass energy, while
its soil-enriching properties combat land degradation.
                            </p>
                            
                        </Typography>

                        
                    </Paper>
            </div>
          </React.Fragment>
        );
    }
    }

    NapierGrassPropagation.propTypes = {
        classes: PropTypes.object.isRequired
      };
      
      export default withStyles(styles)(NapierGrassPropagation);
